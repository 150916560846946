import React, { useEffect } from 'react';
import { Marker, Popup, Tooltip } from 'react-leaflet';

import L from 'leaflet';
import { promiseToFlyTo } from 'lib/map';
import { Destinations } from '../hooks/useDestinations';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import MapComponent from '../components/Map.tsx';


const LOCATION = {
  lat: 41.90654,
  lng: 14.48625,
};

const CENTER = [LOCATION.lat, LOCATION.lng];
const DEFAULT_ZOOM = 2;
const ZOOM = 8;

const timeToZoom = 1500;
const timeToOpenPopupAfterZoom = 4000;
const timeToUpdatePopupAfterZoom = timeToOpenPopupAfterZoom + 3000;

const MapPage: React.FunctionComponent = () => {
  async function mapEffect({ leafletElement } = {}) {
    if (!leafletElement) return;
    const location = await LOCATION;
    setTimeout(async () => {
      await new promiseToFlyTo(leafletElement, {
        zoom: ZOOM,
        center: location,
      });
    }, timeToZoom);
  }

  const mapSettings = {
    center: CENTER,
    defaultBaseMap: 'pro',
    zoom: DEFAULT_ZOOM,
    mapEffect,
  };

  const { freefoods } = Destinations();

  useEffect(() => {
    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: 'https://mossy.earth/images/logo/logo-green.png',
      iconUrl: 'https://mossy.earth/images/logo/logo-green.png',
      shadowUrl: '',
      iconSize: [30, 30]
    });
  }, []);

  return (
    <>
      <GatsbySeo
        title="Map"
        description="In questa mappa e` possibile trovare le risorse di cibo presenti intorno a te, sentiti libero di dargli un' occhiata e di contribuire"
        canonical="https://www.casafraine.com/map"
        openGraph={{
          url: 'https://www.casafraine.com/map',
          title: 'Map',
          description:
            "In questa mappa e` possibile trovare delle risorse di cibo intorno a te, sentiti libero di dargli un' occhiata e di contribuire",
       
          images: [
            {
              url: 'https://www.casafraine.com/mapitaly.png',
              width: 800,
              height: 600,
              alt: 'map',
            },
            {
              url: 'https://www.casafraine.com/mapitaly.png',
              width: 900,
              height: 800,
              alt: 'map',
            },
            { url: 'https://www.casafraine.com/mapitaly.png' },
            { url: 'https://www.casafraine.com/mapitaly.png' },
          ],
          site_name: 'Casa Fraine',
        }}
        twitter={{
          handle: 'Vincenzo Marcovecchio',
          site: 'www.casafraine.com/map',
          cardType: 'summary_large_image',
        }}
      />

      <div className="map page-map">
        <MapComponent
          style={{ height: '80vh' }}
          attributionControl={false}
          {...mapSettings}
        >
          {freefoods.map((food) => {
            const { id, name, location, picking_time, description } = food;
            const position = [location.latitude, location.longitude];
            return (
              <Marker key={id} riseOnHover="true" position={position}>
                <Tooltip key={id}>
                  {name}
                  Picking time:&nbsp;{picking_time}
                </Tooltip>
                <Popup key={id}>
                  {name}&nbsp;
                  {picking_time}
                  {description && (
                    <img
                      key={id}
                      style={{
                        width: '100%',
                        height: '40vh',
                        objectFit: 'cover',
                      }}
                      src={description}
                      alt="fruit tree"
                    />
                  )}
                </Popup>
              </Marker>
            );
          })}
          <span
            className="tool"
            data-tip="Head over to the about section to get in touch with us."
            tabIndex="1"
            aria-label="Head over to the about section to get in touch with us."
          >
            contribute
          </span>
        </MapComponent>
        <div id="info" style={{ height: 100, width: 500 }} />
      </div>
    </>
  );
};

export default MapPage;
