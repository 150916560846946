import React, { useRef, FC } from "react";
import { MapContainer, Marker, TileLayer, ZoomControl } from "react-leaflet";
import { useConfigureLeaflet, useMapServices, useRefEffect } from "../hooks";
import { isDomAvailable } from "../lib/util";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Helmet } from "react-helmet";
const DEFAULT_MAP_SERVICE = "OpenStreetMap";

// interface labeledProps {
//   children: ReactChild | ReactChildren;
//   className: string;
//   defaultBaseMap: string;
//   mapEffect: func;
// }

const MapComponent: FC = (props) => {
  const {
    children,
    className,
    defaultBaseMap = DEFAULT_MAP_SERVICE,
    mapEffect,
    ...rest
  } = props;

  const mapRef = useRef();

  useConfigureLeaflet();

  useRefEffect({
    ref: mapRef,
    effect: mapEffect,
  });

  const services = useMapServices({
    names: [...new Set([defaultBaseMap, DEFAULT_MAP_SERVICE])],
  });
  const basemap = services.find((service) => service.name === defaultBaseMap);

  let mapClassName = `map`;

  if (className) {
    mapClassName = `${mapClassName} ${className}`;
  }

  if (!isDomAvailable()) {
    return (
      <div className={mapClassName}>
        <p className="map-loading">Loading map...</p>
      </div>
    );
  }

  const mapSettings = {
    className: "map-base",
    zoomControl: false,
    ...rest,
  };

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.0.1/dist/leaflet.css"
        />
      </Helmet>
      <div className={mapClassName}>
        <MapContainer ref={mapRef} {...mapSettings}>
          {children}
          {basemap && <TileLayer {...basemap} />}
          <ZoomControl position="bottomright" />
        </MapContainer>
      </div>
    </>
  );
};

export default MapComponent;
