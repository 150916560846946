import { graphql, useStaticQuery } from 'gatsby';

export function Destinations() {
  const { gcms = {} } = useStaticQuery(graphql`
    query {
      gcms {
        freefoods {
          name
          picking_time
          description
          location {
            latitude
            longitude
          }
        }
      }
    }
  `);

  let { freefoods } = gcms;

  return {
    freefoods,
  };
}
